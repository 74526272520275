import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from '@material-ui/core';
import * as VideoExpress from '@vonage/video-express';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
// import OT from '@opentok/client';
import useStyles from './styles';
import usePreviewPublisher from '../../hooks/usePreviewPublisher';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core';
import AudioSettings from '../AudioSetting';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import VideoSettings from '../VideoSetting';
import DeviceAccessAlert from '../DeviceAccessAlert';
import { UserContext } from '../../context/UserContext';
import LinearProgress from '@material-ui/core/LinearProgress';
import { DEVICE_ACCESS_STATUS } from './../constants';
import useBackgroundBlur from '../../hooks/useBackgroundBlur';
import { useLocation } from "react-router-dom";
import { getPreCallCredentials } from '../../api/fetchCreds';
import FlashMessage from 'components/FlashMessage';
import StaffPhoneArea from 'components/StaffPhoneArea';
export default function WaitingRoom({ location }) {
  const { stopEffect, startBackgroundBlur, isSupported } = useBackgroundBlur();
  const classes = useStyles();
  const { push } = useHistory();
  const { user, setUser } = useContext(UserContext);
  const waitingRoomVideoContainer = useRef();
  let { roomName } = useParams();
  const meetingType=location.state.meetingType;
  const [userName, setUserName] = useState('-');
  const theme = useTheme();
  const isMobileWidth = useMediaQuery(theme.breakpoints.down('xs'));
  const [matchmakingAt, setMatchmakingAt] = useState();
  const [isWaitingForMatchmakingAt, setIsWaitingForMatchmakingAt] = useState(false);
  const [isRoomNameInvalid, setIsRoomNameInvalid] = useState(false);
  const [isUserNameInvalid, setIsUserNameInvalid] = useState(false);
  const [staffPhoneNumber, setStaffPhoneNumber] = useState();
  const [showStaffPhoneNumber, setShowStaffPhoneNumber] = useState(false);
  const [showErrorAndReload, setShowErrorAndReload] = useState(false);
  const [localAudio, setLocalAudio] = useState(
    user.defaultSettings.publishAudio
  );
  const [localVideo, setLocalVideo] = useState(
    user.defaultSettings.publishVideo
  );
  const [localVideoSource, setLocalVideoSource] = useState(undefined);
  const [localAudioSource, setLocalAudioSource] = useState(undefined);
  const [localAudioOutput, setLocalAudioOutput] = useState(undefined);
  /* const [devices, setDevices] = useState(null); */
  let [audioDevice, setAudioDevice] = useState('');
  let [videoDevice, setVideoDevice] = useState('');
  let [audioOutputDevice, setAudioOutputDevice] = useState('');
  const [backgroundBlur, setBackgroundBlur] = useState(
    user.videoEffects.backgroundBlur
  );
  const [credentials, setCredentials] = useState(null);
  const {
    createPreview,
    destroyPreview,
    previewPublisher,
    logLevel,
    previewMediaCreated,
    deviceInfo,
    accessAllowed,
  } = usePreviewPublisher();

  const handleVideoSource = React.useCallback(
    (e) => {
      const videoDeviceId = e.target.value;
      setVideoDevice(e.target.value);
      previewPublisher.setVideoDevice(videoDeviceId);
      setLocalVideoSource(videoDeviceId);
    },
    [previewPublisher, setVideoDevice, setLocalVideoSource]
  );

  const handleAudioSource = React.useCallback(
    (e) => {
      const audioDeviceId = e.target.value;
      setAudioDevice(audioDeviceId);
      previewPublisher.setAudioDevice(audioDeviceId);
      setLocalAudioSource(audioDeviceId);
    },
    [previewPublisher, setAudioDevice, setLocalAudioSource]
  );

  const handleAudioOutput = React.useCallback(
    (e) => {
      const audioOutputId = e.target.value;
      setAudioOutputDevice(audioOutputId);
      // await VideoExpress.setAudioOutputDevice(audioOutputId);
      setLocalAudioOutput(audioOutputId);
    },
    [setLocalAudioOutput, setAudioOutputDevice]
  );

  const redirectHttps = React.useCallback(() => {
    const url = window.location.href;
    if (
      url.toString().indexOf('http://') === 0 &&
      url.toString().indexOf('http://localhost') !== 0
    ) {
      window.location.href = window.location.href
        .toString()
        .replace('http://', 'https://');
    } else {
      return;
    }
  }, []);

  const handleJoinClick = () => {
    if (validateForm()) {
      if(matchmakingAt < new Date().getTime()){
        push({
              pathname:`/room/${roomName}`,
              state: {meetingType: meetingType}
          });
      }else{
        setIsWaitingForMatchmakingAt(true);
        const interval = setInterval(() => {
          if( matchmakingAt < new Date().getTime()){
            clearInterval(interval);
            setIsWaitingForMatchmakingAt(false);
            push({
                  pathname:`/room/${roomName}`,
                  state: {meetingType: meetingType}
              });
          }
        }, 1000);
      }
    }
  };


  const validateForm = () => {
    if (userName === '') {
      setIsUserNameInvalid(true);
      return false;
    } else if (roomName === '') {
      setIsRoomNameInvalid(true);
      return false;
    }
    return true;
  };



  const onChangeParticipantName = (e) => {
    const userName = e.target.value;
    if (userName === '' || userName.trim() === '') {
      // Space detected
      setUserName('');
      return;
    }
    setIsUserNameInvalid(false);
    setUserName(userName);
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleJoinClick();
    }
  };

  const handleAudioChange = React.useCallback((e) => {
    setLocalAudio(e.target.checked);
  }, []);

  const handleVideoChange = React.useCallback((e) => {
    setLocalVideo(e.target.checked);
  }, []);

  const handleChangeBackgroundBlur = React.useCallback(async () => {
    console.log('handleChangeBackgroundBlur!!!')
    try {
      if (backgroundBlur) {
        setBackgroundBlur(false);
        destroyPreview();
        stopEffect();
        createPreview(waitingRoomVideoContainer.current, {
          videoSource: localVideoSource,
        });
      } else {
        setBackgroundBlur(true);
        destroyPreview();
        const outputVideoStream = await startBackgroundBlur(videoDevice);
        console.log(outputVideoStream);
        createPreview(waitingRoomVideoContainer.current, {
          videoSource: outputVideoStream.getVideoTracks()[0],
          mirror: true,
        });
      }
    } catch (e) {
      console.log(`Could not send background blurring - ${e}`);
    }
  }, [
    backgroundBlur,
    destroyPreview,
    stopEffect,
    createPreview,
    localVideoSource,
    videoDevice,
    startBackgroundBlur,
  ]);

  useEffect(() => {
    redirectHttps();
  }, [redirectHttps]);

  useEffect(() => {
    getPreCallCredentials(roomName,null,meetingType)
      .then(({ data }) => {
        console.log('watitingroom getCredentials')
        setMatchmakingAt(new Date(data.matchmakingAt));
        setStaffPhoneNumber(data.staffPhoneNumber)
      })
      .catch((err) => {
        console.log(err);
        setShowErrorAndReload(true);
        window.setTimeout(function(){
          window.location.href = process.env.REACT_APP_API_URL_PRODUCTION;
        }, 3000);
      });
  }, [roomName]);


  useEffect(() => {
    if (
      localAudio !== user.defaultSettings.publishAudio ||
      localVideo !== user.defaultSettings.publishVideo ||
      localAudioSource !== user.defaultSettings.audioSource ||
      localVideoSource !== user.defaultSettings.videoSource ||
      backgroundBlur !== user.videoEffects.backgroundBlur ||
      localAudioOutput !== user.defaultSettings.audioOutput
    ) {
      setUser({
        ...user,
        videoEffects: {
          backgroundBlur: backgroundBlur,
          videoSourceId: localVideoSource,
        },
        defaultSettings: {
          publishAudio: localAudio,
          publishVideo: localVideo,
          audioSource: localAudioSource,
          videoSource: localVideoSource,
          audioOutput: localAudioOutput,
        },
      });
    }
  }, [
    localAudio,
    localVideo,
    user,
    setUser,
    localAudioSource,
    localVideoSource,
    backgroundBlur,
    localAudioOutput,
  ]);

  useEffect(() => {
    if (userName !== user.userName) {
      setUser({ ...user, userName: userName });
    }
  }, [userName, user, setUser]);

  useEffect(() => {
    if (previewPublisher && previewMediaCreated && deviceInfo) {
      previewPublisher.getAudioDevice().then((currentAudioDevice) => {
        setAudioDevice(currentAudioDevice.deviceId);
      });
      const currentVideoDevice = previewPublisher.getVideoDevice();
      setVideoDevice(currentVideoDevice.deviceId);

      VideoExpress.getActiveAudioOutputDevice().then(
        (currentAudioOutputDevice) => {
          setAudioOutputDevice(currentAudioOutputDevice.deviceId);
        }
      );
    }
  }, [
    deviceInfo,
    previewPublisher,
    setAudioDevice,
    setVideoDevice,
    previewMediaCreated,
    setAudioOutputDevice,
  ]);

  useEffect(() => {
    if (previewPublisher) {
      if (localAudio && !previewPublisher.isAudioEnabled()) {
        previewPublisher.enableAudio();
      } else if (!localAudio && previewPublisher.isAudioEnabled()) {
        previewPublisher.disableAudio();
      }
    }
  }, [localAudio, previewPublisher]);

  useEffect(() => {
    if (previewPublisher) {
      if (localVideo && !previewPublisher.isVideoEnabled()) {
        previewPublisher.enableVideo();
      } else if (!localVideo && previewPublisher.isVideoEnabled()) {
        previewPublisher.disableVideo();
      }
    }
  }, [localVideo, previewPublisher]);

  useEffect(() => {
    console.log('createPreview, destroyPreview, stopEffect')
    if (waitingRoomVideoContainer.current) {
      createPreview(waitingRoomVideoContainer.current);
    }

    return () => {
      stopEffect();
      destroyPreview();
    };
  }, [createPreview, destroyPreview, stopEffect]);
  useEffect(() => {
    if(isSupported){
      setBackgroundBlur(true);
      handleChangeBackgroundBlur();
    }
  }, []);
  return (
    <>
      <div className={classes.waitingRoomContainer}>
        { showErrorAndReload  && (<FlashMessage messageText={'面談が存在しません'} />)}
        <div className={` ${classes.textMini}`}>マイク・カメラがONになっていることを確認ください。</div>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >


          <div>
            <div
              id="waiting-room-video-container"
              className={classes.waitingRoomVideoPreview}
              ref={waitingRoomVideoContainer}
            ></div>
            <div className={classes.deviceContainer}>
              <AudioSettings
                className={classes.deviceSettings}
                hasAudio={localAudio}
                onAudioChange={handleAudioChange}
              />
              <LinearProgress variant="determinate" value={logLevel} />
              <VideoSettings
                className={classes.deviceSettings}
                hasVideo={localVideo}
                onVideoChange={handleVideoChange}
              />
            </div>
          </div>
          <form className={classes.form} noValidate>
            <div className={classes.mediaSources}>
              {deviceInfo && previewMediaCreated  && (
                <>

                  <div className={` ${classes.textMini}`}>「マイク・音声の設定」</div>
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">
                      マイク
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={audioDevice}
                      onChange={handleAudioSource}
                    >
                      {deviceInfo.audioInputDevices.map((device) => (
                        <MenuItem key={device.deviceId} value={device.deviceId} className={classes.whiteSpaceNormal}>
                          {device.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl>
                    <InputLabel id="video">音声</InputLabel>
                    {deviceInfo.audioOutputDevices && (
                      <Select
                        labelId="video"
                        id="demo-simple-select"
                        value={audioOutputDevice}
                        onChange={handleAudioOutput}
                      >
                        {deviceInfo.audioOutputDevices.map((device) => (
                          <MenuItem
                            key={device.deviceId}
                            value={device.deviceId}
                          >
                            {device.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </FormControl>
                </>
              )}

            </div>
          </form>
          {/*{isSupported && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={backgroundBlur}
                  onChange={handleChangeBackgroundBlur}
                />
              }
              label="Background Blur"
            />
          )}*/}
        </Grid>

        {isWaitingForMatchmakingAt && (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.margin10}
          >
            <div className={classes.errorContainer}>
              {'面談開始時間までお待ちください。' + matchmakingAt.getHours() + ':' + matchmakingAt.getMinutes() + 'になると自動的に面談が開始いたします。'}
            </div>
          </Grid>
        )}
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classes.margin10}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleJoinClick}
            disabled={!roomName || !userName || isWaitingForMatchmakingAt}
          >
            面談室に入る
          </Button>
        </Grid>
        <StaffPhoneArea staffPhoneNumber={staffPhoneNumber} />
      </div>
      {accessAllowed !== DEVICE_ACCESS_STATUS.ACCEPTED && 1==2 && (
        <DeviceAccessAlert accessStatus={accessAllowed}></DeviceAccessAlert>
      )}
    </>
  );
}
